/* eslint-disable @next/next/no-img-element */
import { basicPageProps, fetchTemplate, mobileProps } from '@/utils/isServer';
import { absoluteServerPath, CDN2, resolveConfigUrl } from '@helpers/api';
import { cacheFetch, fetchSearchAlternate } from '@helpers/cacheFetch';
import { Button, Collapse, Rate, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import SearchStore from '@/stores/SearchStore';
import { useEffect, useRef, useState } from 'react';
import { useMobxStores } from '@/stores';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import OldParking from '@pages/myapp2/parking/[friendlyUrl]';
import ExtraLandingComponentB from '@/components/ExtraLandingComponentB';
import useABHook from '@/hooks/useABHook';
import { shuffleReviews } from '@/utils/fakeReviews';
import * as XMLJS from 'xml-js';

// window.postMessage('testa')
// window.postMessage('testb')

const SectionItem = dynamic(() => import('@components/Landing/SectionItem'), {
  ssr: true,
});

const { Panel } = Collapse;
const { Meta } = Card;

function extractPrefixSuffix(url) {
  let supressFromHome = false;
  let suffix = '';
  let prefix = '/parking/';
  if (url.indexOf('?') != -1) {
    suffix = url.substring(url.indexOf('?'));
  }
  if (url.indexOf('/myapp') != -1) {
    prefix = '/myapp/parking/';
  }
  if (suffix == '?fromhome=true') {
    suffix = '';
    supressFromHome = true;
  }
  return [prefix, suffix, supressFromHome];
}

const SLUG_MAP = {
  'JohnFKennedy-International-Airport-JFK':
    'John-F-Kennedy-International-Airport-JFK',
  'Penn-Station-Parking': 'Penn-Station-Parking-pns',
  'AJacksonville-International-Airport-JAX':
    'Jacksonville-International-Airport-JAX',
  'TFGreen-International-Airport-PVD': 'Providence-International-Airport-PVD',
  'Southwest-Florida-International-Airport-RSW':
    'Fort-Myers-Southwest-Florida-International-Airport-RSW',
  'Tallahassee-International-Airport-Parking-THL':
    'Tallahassee-International-Airport-Parking-TLH',
};

// // This also gets called at build time
export const getServerSideProps: GetServerSideProps = async ({
  req,
  params,
  query,
}) => {
  const { isMobile, isIos } = mobileProps({ req });
  const { configInfo, airports } = await basicPageProps({ req });
  const baseUrl = absoluteServerPath(configInfo);
  const { t } = query; // Preview
  const checkin = query?.checkin ?? null;
  const checkout = query?.checkout ?? null;

  let landing;
  let CUSTOM_SLUGS = {};
  try {
    CUSTOM_SLUGS = JSON.parse(
      await cacheFetch(`${baseUrl}/api/metadata/loadone/custom_slugs`)
    );
  } catch (e) {
    console.log(e);
  }
  const oriFriendlyUrl = params.slug as string;
  let friendlyUrl = oriFriendlyUrl;
  if (CUSTOM_SLUGS[oriFriendlyUrl]) {
    friendlyUrl = CUSTOM_SLUGS[oriFriendlyUrl].target_slug;
  }
  if (SLUG_MAP[friendlyUrl]) {
    friendlyUrl = SLUG_MAP[friendlyUrl];
    const [prefix, suffix] = extractPrefixSuffix(req.url);
    return {
      redirect: {
        destination: prefix + friendlyUrl + suffix,
        permanent: true,
      },
    } as any;
  }
  try {
    if (t === 'preview') {
      const landingResponse = await fetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}?t=preview`,
        { ...fetchTemplate }
      );
      landing = await landingResponse.json();
    } else {
      landing = await cacheFetch(
        `${baseUrl}/api/AirportLanding/${friendlyUrl}`
      );
    }
    if (
      t !== 'preview' &&
      landing.airport_frienldy_url &&
      landing.airport_frienldy_url != friendlyUrl
    ) {
      const [prefix, suffix] = extractPrefixSuffix(req.url);
      return {
        redirect: {
          destination: prefix + landing.airport_frienldy_url + suffix,
          permanent: true,
        },
      } as any;
    }
  } catch (e) {
    return {
      redirect: {
        destination: '/',
      },
    } as any;
  }
  const searchAlternate = await fetchSearchAlternate(
    baseUrl,
    landing.airport_id,
    checkin,
    checkout
  );
  const [prefix, suffix, supressFromHome] = extractPrefixSuffix(req.url);
  if (supressFromHome) {
    return {
      redirect: {
        destination: prefix + landing.airport_frienldy_url + suffix,
        permanent: true,
      },
    } as any;
  }
  if (landing.properties && CUSTOM_SLUGS[oriFriendlyUrl]) {
    if (CUSTOM_SLUGS[oriFriendlyUrl].price) {
      landing.properties.price = CUSTOM_SLUGS[oriFriendlyUrl].price;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].seo_title) {
      landing.properties.seo_title = CUSTOM_SLUGS[oriFriendlyUrl].seo_title;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
    if (CUSTOM_SLUGS[oriFriendlyUrl].display_name) {
      landing.properties.display_name =
        CUSTOM_SLUGS[oriFriendlyUrl].display_name;
    }
  }

  let dataJsonStr = null;
  if (
    landing?.extra?.extra_enabled &&
    landing?.extra?.extra_google_news_search
  ) {
    const googleNewsLink = `https://news.google.com/rss/search?hl=en-US&gl=US&ceid=US:en&q=${encodeURI(
      landing?.extra?.extra_google_news_search
    )}`;
    const dataFetchContent = await cacheFetch(googleNewsLink, true);
    const dataJson: any = XMLJS.xml2js(dataFetchContent, {
      compact: true,
      ignoreComment: true,
      alwaysChildren: true,
    });
    dataJsonStr = dataJson.rss.channel.item.slice(0, 5).map((x) => {
      x.pubDate._text = dayjs(x.pubDate._text).format('ddd, D MMM YYYY');
      return x;
    });
  }
  return {
    props: {
      landing,
      airports,
      searchAlternate,
      configInfo,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
      checkin,
      checkout,
      fixedReviews: shuffleReviews(),
      googleNews: dataJsonStr,
    },
  };
};

// pages/parking/[friendlyUrl].js
function Parking({
  landing,
  airports,
  searchAlternate,
  configInfo,
  deviceType,
  mobileType,
  checkin,
  checkout,
  fixedReviews,
  googleNews,
}) {
  const landingAirportInitials = landing?.properties?.airport_initials;
  const selectedAirport =
    landing?.airport_display_type == 1
      ? ''
      : JSON.stringify({
          airportinitials: landingAirportInitials,
          id: landing?.properties?.airport_id,
          value: `${landingAirportInitials} - ${landing?.properties?.airport_name}`,
        });
  useABHook('test1', () => {
    sessionStorage.setItem('abtest', '1');
  });
  const router = useRouter();
  const dateNow = dayjs();
  let icheckin =
    router.query?.checkin && router.query?.checkin != 'undefined'
      ? dayjs(router.query.checkin as string)
          .hour(dateNow.hour())
          .minute(dateNow.minute())
          .second(dateNow.second())
          .millisecond(dateNow.millisecond())
      : dateNow;
  let icheckout =
    router.query?.checkout && router.query?.checkout != 'undefined'
      ? dayjs(router.query.checkout as string)
          .hour(dateNow.hour())
          .minute(dateNow.minute())
          .second(dateNow.second())
          .millisecond(dateNow.millisecond())
      : dateNow.add(1, 'day');

  if (icheckin.isBefore(dateNow) || icheckout.isBefore(dateNow)) {
    icheckin = dateNow;
    icheckout = dateNow.add(1, 'day');
  }

  return (
    <>
      {!landing?.extra?.extra_enabled && (
        <OldParking
          airports={airports}
          searchAlternate={searchAlternate}
          checkin={checkin}
          checkout={checkout}
          landing={landing}
          mobileType={mobileType}
          deviceType={deviceType}
          configInfo={configInfo}
        />
      )}
      {landing?.extra?.extra_enabled && (
        <ExtraLandingComponentB
          airports={airports}
          searchAlternate={searchAlternate}
          checkin={checkin}
          checkout={checkout}
          icheckin={icheckin}
          icheckout={icheckout}
          landing={landing}
          deviceType={deviceType}
          selectedAirport={selectedAirport}
          configInfo={configInfo}
          fixedReviews={fixedReviews}
          googleNews={googleNews}
        />
      )}
    </>
  );
}

Parking.layout = 'LandingPageLayout';

export default Parking;
